import { Sprite } from "pixi.js";

const sizeQuery = window.location.search.split("size=");

export const MAZE_SIZE =
  !!sizeQuery && sizeQuery.length > 1 ? parseInt(sizeQuery[1]) : 8;
export const MAZE_SIZE_WITH_WALLS = MAZE_SIZE * 2;

export const TILE_SIZE = 16;
const borderWidth = 20;
export const maximumPixelWidth =
  (window.innerWidth > window.innerHeight
    ? window.innerHeight
    : window.innerWidth) - borderWidth;
const SCALE = maximumPixelWidth / MAZE_SIZE_WITH_WALLS / TILE_SIZE;

export const TILES_X = 8;
export const TILES_Y = 15;
export const SCALE_X = SCALE;
export const SCALE_Y = SCALE;
export const PIXEL_WIDTH = maximumPixelWidth; // TILE_SIZE * MAZE_SIZE_WITH_WALLS * SCALE_X;
export const PIXEL_HEIGHT = maximumPixelWidth; // TILE_SIZE * MAZE_SIZE_WITH_WALLS * SCALE_Y;
export const SPEED = (TILE_SIZE * SCALE) / 4;

export enum TileType {
  WALL = TILES_X * 4 + 6,
  PATH = TILES_X * 8 + 1,
  DOOR = TILES_X * 6 + 0,
  TREASURE = TILES_X * 4 + 4,
}

export enum GameStatus {
  NOT_STARTED,
  IN_PROGRESS,
  GAME_OVER,
  WON,
}

export type GameItem = { sprite: Sprite; url: string };
export type GameTile = { gameItem: GameItem; tileType: TileType };

export const GameState = {
  playerSprite: null as GameItem | null,
  monsters: [] as GameItem[],
  npcs: [] as GameItem[],
  terrain: [] as GameItem[],
  items: [] as GameItem[],
  activeLayout: [] as GameTile[][],
  goalTile: undefined as GameTile | undefined,
  gameStatus: GameStatus.IN_PROGRESS as GameStatus,
};
