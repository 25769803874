import * as PIXI from "pixi.js";
import { tick } from "./tick";
import { setup } from "./setup";
import {
  TILE_SIZE,
  MAZE_SIZE,
  GameStatus,
  GameState,
  SCALE_X,
  SCALE_Y,
  MAZE_SIZE_WITH_WALLS,
} from "./GameState";

window.onload = () => {
  document.querySelector("#playagain").addEventListener("click", () => {
    window.location.href = "/";
  });

  if (!window.location.href.includes("size")) {
    const menu = document.querySelector("#menu");
    if (menu) {
      menu.classList.remove("hidden");
      menu.querySelectorAll("button").forEach((e) => {
        e.addEventListener("click", () => {
          const num = e.getAttribute("data-size");
          console.log(e);
          if (num) {
            console.log("SETTING SIZE", num);
            window.location.href = window.location.origin + `?size=${num}`;
            menu.classList.add("hidden");
            startApp();
          }
        });
      });
    }
  } else {
    startApp();
  }
};

function startApp() {
  //Create a Pixi Application
  console.log("start app sizes", MAZE_SIZE, " ", MAZE_SIZE_WITH_WALLS);
  const app = new PIXI.Application({
    width: TILE_SIZE * MAZE_SIZE_WITH_WALLS * SCALE_X, // window.innerWidth,
    height: TILE_SIZE * MAZE_SIZE_WITH_WALLS * SCALE_Y, // window.innerHeight,
    resolution: 1,
  });
  app.renderer.backgroundColor = 0x6caa2c;

  //Add the canvas that Pixi automatically created for you to the HTML document

  document.querySelector("#myapp").appendChild(app.view);

  setup(app, render);
}

let timeLast = Date.now();
let timeDelta = 0;
function render() {
  if (GameState.gameStatus === GameStatus.WON) {
    document.querySelector("#wintext").classList.toggle("hidden");
    return;
  }
  timeDelta = Date.now() - timeLast;
  tick(timeDelta);
  timeLast = Date.now();
  window.requestAnimationFrame(render);
}
