import {
    GameState,
    TILE_SIZE,
    TILES_X,
    TILES_Y,
    TileType,
    GameTile,
    MAZE_SIZE,
    SCALE_X,
    SCALE_Y,
    maximumPixelWidth,
} from './GameState';
import { keyManager } from './KeyManager';
import * as PIXI from 'pixi.js';
import MazeGenerator from 'generate-maze';

type MazeItem = {
    x: 4; // Horizontal position, integer
    y: 7; // Vertical position, integer
    top: false; // Top/Up has a wall/blocked if true, boolean
    left: false; // Left has a wall/blocked if true, boolean
    bottom: true; // Bottom/Down has a wall/blocked if true, boolean
    right: true; // Right has a wall/blocked if true, boolean
    set: 5; // Set # used to generate maze, can be ignored
};

const captainTex = require('../assets/pirates/PNG/24x32/captain-m-001-light.png');
const tileset = require('../assets/basictiles.png');
const loader = new PIXI.Loader();

// const scaleX = 2;
// const scaleY = 2;

export function setup(app: PIXI.Application, render: () => void) {
    loader.add(captainTex).load(() => onLoad(app, render));
}

function onLoad(app: PIXI.Application, render) {
    const container = new PIXI.Container();
    app.stage.addChild(container);
    container.width = maximumPixelWidth;
    container.height = maximumPixelWidth;
    container.sortableChildren = true;

    loadTileset(container, TILES_X, TILES_Y);
    const captain = new PIXI.Sprite(loader.resources[captainTex].texture);
    captain.texture.frame = new PIXI.Rectangle(24, 64, 24, 32);
    captain.width = (24 / 32) * 16 * SCALE_X - 4;
    captain.height = 16 * SCALE_Y - 4;
    captain.zIndex = 1;
    container.addChild(captain);
    GameState.playerSprite = { sprite: captain, url: captainTex };

    window.addEventListener('keydown', keyManager.keyListener.bind(keyManager, true));
    window.addEventListener('keyup', keyManager.keyListener.bind(keyManager, false));
    keyManager.setupTouchListeners(app.view, GameState.playerSprite.sprite);

    render();
}

function getTile(x: number, y: number) {
    return new PIXI.Rectangle(TILE_SIZE * x, TILE_SIZE * y, TILE_SIZE, TILE_SIZE);
}

function loadTileset(app: PIXI.Container, x: number, y: number) {
    const TexCache = loader.resources;
    loader.add(tileset).load(() => {
        for (let i = 0; i < y; i++) {
            for (let j = 0; j < x; j++) {
                const texture = TexCache[tileset].texture.clone();
                const gameTile = new PIXI.Sprite(texture);
                gameTile.texture.frame = getTile(j, i);
                gameTile.width = SCALE_X * TILE_SIZE;
                gameTile.height = SCALE_Y * TILE_SIZE;

                GameState.terrain.push({
                    sprite: gameTile,
                    url: `${j},${i}`,
                });
            }
        }

        setupLevel(app);
    });
}

function setupLevel(app: PIXI.Container) {
    const maze: MazeItem[][] = MazeGenerator(MAZE_SIZE);
    const layout: GameTile[][] = [];

    for (let i = 0; i < MAZE_SIZE * 2; i++) {
        layout.push([]);
        for (let j = 0; j < MAZE_SIZE * 2; j++) {
            layout[i].push({ gameItem: { ...GameState.terrain[TileType.WALL] }, tileType: TileType.WALL });

            if (i % 2 === 1 && j % 2 === 1) {
                const mazeItem = maze[Math.floor(i / 2)][Math.floor(j / 2)];
                layout[i][j] = { gameItem: { ...GameState.terrain[TileType.PATH] }, tileType: TileType.PATH };

                if (!mazeItem.left) {
                    layout[i - 1][j] = { gameItem: { ...GameState.terrain[TileType.PATH] }, tileType: TileType.PATH };
                }
                if (!mazeItem.top) {
                    layout[i][j - 1] = { gameItem: { ...GameState.terrain[TileType.PATH] }, tileType: TileType.PATH };
                }
            }
        }
    }

    for (let j = 0; j < MAZE_SIZE * 2; j++) {
        const i = MAZE_SIZE * 2 - 2;

        const mazeItem = maze[Math.floor(i / 2)][Math.floor(j / 2)];
        if (!mazeItem.bottom) {
            layout[i + 1][j] = { gameItem: GameState.terrain[TileType.PATH], tileType: TileType.PATH };
        }
    }

    for (let i = 0; i < MAZE_SIZE * 2; i++) {
        const j = MAZE_SIZE * 2 - 2;

        const mazeItem = maze[Math.floor(i / 2)][Math.floor(j / 2)];
        if (!mazeItem.right) {
            layout[i][j + 1] = { gameItem: GameState.terrain[TileType.PATH], tileType: TileType.PATH };
        }
    }

    GameState.activeLayout = layout;

    const longestPathEndNode = findPathDP(layout);

    // If no path was found, try again
    if (!longestPathEndNode) {
        return setupLevel(app);
    }

    const [startNodeX, startNodeY] = longestPathEndNode.startOfLongestPath;
    const [endNodeX, endNodeY] = longestPathEndNode.endPos as [number, number];
    GameState.activeLayout[startNodeX][startNodeY] = {
        gameItem: GameState.terrain[TileType.DOOR],
        tileType: TileType.DOOR,
    };
    GameState.activeLayout[endNodeX][endNodeY] = {
        gameItem: GameState.terrain[TileType.TREASURE],
        tileType: TileType.TREASURE,
    };

    for (let i = 0; i < GameState.activeLayout.length; i++) {
        for (let j = 0; j < GameState.activeLayout[0].length; j++) {
            const s = new PIXI.Sprite(GameState.activeLayout[i][j].gameItem.sprite.texture);
            s.zIndex = 0;
            app.addChild(s);
            s.x = i * TILE_SIZE * SCALE_X;
            s.y = j * TILE_SIZE * SCALE_Y;
            s.width = TILE_SIZE * SCALE_X;
            s.height = TILE_SIZE * SCALE_Y;
            GameState.activeLayout[i][j].gameItem.sprite = s;
        }
    }

    GameState.goalTile = GameState.activeLayout[endNodeX][endNodeY];
    GameState.playerSprite.sprite.x = startNodeX * TILE_SIZE * SCALE_X;
    GameState.playerSprite.sprite.y = startNodeY * TILE_SIZE * SCALE_Y;
}

function findPathDP(layout: readonly GameTile[][]) {
    // start at 0,0
    // traverse in double for (n^2)
    // mark visited nodes as such.
    //      make sure to mark open edge tiles on left and top side as start nodes.
    //      when visiting a new node, if the tile is open, set longest path until this point on node. (look at x-1 and y-1).
    //              make sure to also set the start node for the current longest path on this node.
    //      when reaching a node on the right or bottom edge, and it is open, set as an "ending node" in separate array.
    // Find longest path of all ending nodes in "endNode" array. This is the longest path.

    type Node = {
        isStart: Boolean;
        isEnd: Boolean;
        previous: [number, number] | null;
        longestPath: number;
        startOfLongestPath: [number, number] | null;
        endPos?: [number, number];
    };

    const endNodes: Node[] = [];
    const startNodes: Node[] = [];
    const nodeMap: Array<Array<Node | null>> = [];

    for (let i = 0; i < layout.length; i++) {
        nodeMap.push([]);
        for (let j = 0; j < layout[0].length; j++) {
            nodeMap[i].push(null);
            const item = layout[i][j];
            const node: Node = {} as any;

            if (item.tileType === TileType.PATH) {
                if (
                    (i === 0 && j <= layout[0].length / 2) || // first half top
                    (i <= layout.length / 2 && j === 0) // first half left
                ) {
                    node.isStart = true;
                    node.longestPath = 1;
                    node.previous = null;
                    node.startOfLongestPath = [i, j];
                    startNodes.push(node);
                } else if (
                    (i === 0 && j > layout[0].length / 2) || // last half top
                    (i > layout.length / 2 && j === 0) || // last half left
                    j === layout[0].length - 1 || // right side
                    i === layout.length - 1 // bottom
                ) {
                    node.isEnd = true;
                    node.endPos = [i, j];
                    endNodes.push(node);
                }

                const prevX = j - 1;
                const prevY = i - 1;

                if (prevX >= 0 && nodeMap[i][prevX]) {
                    node.previous = [i, prevX];
                    node.longestPath = nodeMap[i][prevX].longestPath + 1;
                    node.startOfLongestPath = nodeMap[i][prevX].startOfLongestPath;
                }

                if (
                    prevY >= 0 &&
                    nodeMap[prevY][j] &&
                    (!node.longestPath || nodeMap[prevY][j].longestPath > node.longestPath + 1)
                ) {
                    node.previous = [prevY, j];
                    node.longestPath = nodeMap[prevY][j].longestPath + 1;
                    node.startOfLongestPath = nodeMap[prevY][j].startOfLongestPath;
                }
                if (node.previous || node.isStart) {
                    nodeMap[i][j] = node;
                }
            }
        }
    }

    let longestPathNode: Node | null = null;
    for (const n of endNodes) {
        if (n.longestPath && (longestPathNode === null || n.longestPath > longestPathNode.longestPath)) {
            longestPathNode = n;
        }
    }

    return longestPathNode;
}
