import * as PIXI from 'pixi.js';

class KeyManager {
    #ArrowLeft = false;
    #ArrowRight = false;
    #ArrowUp = false;
    #ArrowDown = false;
    #Enter = false;
    #Spacebar = false;
    #Z = false;
    #X = false;
    #C = false;
    #Escape = false;
    #touchX = 0;
    #touchY = 0;
    #touchDown = false;

    get ArrowLeft() {
        return this.#ArrowLeft;
    }

    get ArrowRight() {
        return this.#ArrowRight;
    }

    get ArrowUp() {
        return this.#ArrowUp;
    }

    get ArrowDown() {
        return this.#ArrowDown;
    }

    get Enter() {
        return this.#Enter;
    }

    get Spacebar() {
        return this.#Spacebar;
    }

    get Z() {
        return this.#Z;
    }

    get X() {
        return this.#X;
    }

    get C() {
        return this.#C;
    }

    get Escape() {
        return this.#Escape;
    }

    get touchX() {
        return this.#touchX;
    }

    get touchY() {
        return this.#touchY;
    }

    get touchDown() {
        return this.#touchDown;
    }

    public containsPointCa(x1: number, x2: number, y1: number, y2: number, px: number, py: number, leeway: number) {
        const xok = px >= x1 - leeway && px <= x2 + leeway;
        const yok = py >= y1 - leeway && py <= y2 + leeway;
        return xok && yok;
    }

    public setupTouchListeners(container: HTMLCanvasElement, sprite: PIXI.Sprite) {
        container.addEventListener('touchstart', (e) => {
            const touch = e.touches.item(0);
            const offset = (e.target as any).getBoundingClientRect();
            if (
                sprite &&
                touch &&
                this.containsPointCa(
                    sprite.x,
                    sprite.x + sprite.width,
                    sprite.y,
                    sprite.y + sprite.height,
                    touch.clientX - offset.x,
                    touch.clientY - offset.y,
                    25,
                )
            ) {
                this.#touchDown = true;
            }
        });
        container.addEventListener('touchend', (e) => {
            this.#touchDown = false;
        });
        container.addEventListener('touchmove', (e) => {
            const touch = e.touches.item(0);
            const offset = (e.target as any).getBoundingClientRect();
            if (this.#touchDown && touch) {
                this.#touchX = touch.clientX - offset.x;
                this.#touchY = touch.clientY - offset.y;
            }
        });
    }

    public keyListener(value: boolean, e: KeyboardEvent) {
        switch (e.key) {
            case 'ArrowLeft':
            case 'a':
                this.#ArrowLeft = value;
                break;
            case 'ArrowRight':
            case 'd':
                this.#ArrowRight = value;
                break;
            case 'ArrowUp':
            case 'w':
                this.#ArrowUp = value;
                break;
            case 'ArrowDown':
            case 's':
                this.#ArrowDown = value;
                break;
            case 'Enter':
                this.#Enter = value;
                break;
            case '(Space character)':
                this.#Spacebar = value;
                break;
            case 'z':
                this.#Z = value;
                break;
            case 'x':
                this.#X = value;
                break;
            case 'c':
                this.#C = value;
                break;
            case 'Escape':
                this.#Escape = value;
                break;
        }
    }
}

export const keyManager = new KeyManager();
export type IKeyManager = typeof keyManager;
