import { Sprite } from 'pixi.js';

export enum Direction {
    LEFT,
    RIGHT,
    TOP,
    BOTTOM,
}

export function isBetween(x: number, a: number, b: number) {
    return a < x && x < b;
}

export function isOutsideBounds(sprite: Sprite, container: { x: number; y: number; width: number; height: number }) {
    const notInsideX = !isBetween(sprite.x, container.x, container.x + container.width);
    const notInsideY = !isBetween(sprite.y, container.y, container.y + container.height);
    const notInsideXW = !isBetween(sprite.x + sprite.width, container.x, container.x + container.width);
    const notInsideYH = !isBetween(sprite.y + sprite.height, container.y, container.y + container.height);

    return [notInsideX || notInsideXW, notInsideY || notInsideYH];
}

export function isIntersectingSprites(sprite1: Sprite, sprite2: Sprite) {
    const insideX = isBetween(sprite1.x, sprite2.x, sprite2.x + sprite2.width);
    const insideY = isBetween(sprite1.y, sprite2.y, sprite2.y + sprite2.height);
    const insideXW = isBetween(sprite1.x + sprite1.width, sprite2.x, sprite2.x + sprite2.width);
    const insideYH = isBetween(sprite1.y + sprite1.height, sprite2.y, sprite2.y + sprite2.height);

    return (insideX || insideXW) && (insideY || insideYH);
}

export function adjust(sprite1: Sprite, sprite2: Sprite, direction: Direction) {
    const intersecting = isIntersectingSprites(sprite1, sprite2);

    if (intersecting) {
        switch (direction) {
            case Direction.LEFT:
                sprite1.x = sprite2.x + sprite2.width;
                break;
            case Direction.RIGHT:
                sprite1.x = sprite2.x - sprite1.width;
                break;
            case Direction.TOP:
                sprite1.y = sprite2.y + sprite2.height;
                break;
            case Direction.BOTTOM:
                sprite1.y = sprite2.y - sprite1.height;
                break;
        }
    }
}
