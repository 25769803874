import {
    GameState,
    GameItem,
    TILE_SIZE,
    GameTile,
    GameStatus,
    PIXEL_WIDTH,
    PIXEL_HEIGHT,
    SCALE_X,
    SCALE_Y,
    TileType,
    SPEED,
} from './GameState';
import { keyManager } from './KeyManager';
import { isOutsideBounds, isIntersectingSprites, adjust, Direction } from './collision/contain';

export function tick(timeDelta: number) {
    updatePos(GameState.playerSprite, timeDelta);
    // simulateMonsters(timeDelta);
}

function updateSpritePosFromKeyManager(sprite: PIXI.Sprite, timeFactor: number) {
    const maxChange = SPEED * timeFactor;
    if (keyManager.touchDown) {
        const xSign = keyManager.touchX - sprite.x >= 0 ? 1 : -1;
        const ySign = keyManager.touchY - sprite.y >= 0 ? 1 : -1;
        const xChange =
            Math.abs(keyManager.touchX - sprite.x) > maxChange ? xSign * maxChange : keyManager.touchX - sprite.x;
        const yChange =
            Math.abs(keyManager.touchY - sprite.y) > maxChange ? ySign * maxChange : keyManager.touchY - sprite.y;
        sprite.x += xChange;
        sprite.y += yChange;
    }
}

function updatePos(entity: GameItem, time: number) {
    const timeFactor = time / 60;
    const lastX = entity.sprite.x;
    const lastY = entity.sprite.y;
    entity.sprite.x += keyManager.ArrowRight ? SPEED * timeFactor : 0;
    entity.sprite.x -= keyManager.ArrowLeft ? SPEED * timeFactor : 0;
    entity.sprite.y += keyManager.ArrowDown ? SPEED * timeFactor : 0;
    entity.sprite.y -= keyManager.ArrowUp ? SPEED * timeFactor : 0;

    updateSpritePosFromKeyManager(entity.sprite, timeFactor);

    const [isCollidingWithXAxisWall, isCollidingWithYAxisWall] = isOutsideBounds(entity.sprite, {
        x: 0,
        y: 0,
        width: PIXEL_WIDTH,
        height: PIXEL_HEIGHT,
    });
    if (isCollidingWithXAxisWall) {
        entity.sprite.x = lastX;
    }
    if (isCollidingWithYAxisWall) {
        entity.sprite.y = lastY;
    }

    const tileposx = Math.floor((entity.sprite.x + entity.sprite.width / 2) / (TILE_SIZE * SCALE_X));
    const tileposy = Math.floor((entity.sprite.y + entity.sprite.height / 2) / (TILE_SIZE * SCALE_Y));

    if (GameState.activeLayout[0]) {
        if (
            tileposx > 0 &&
            tileposy >= 0 &&
            tileposy < GameState.activeLayout[0].length &&
            GameState.activeLayout[tileposx - 1][tileposy].tileType === TileType.WALL
        ) {
            adjust(entity.sprite, GameState.activeLayout[tileposx - 1][tileposy].gameItem.sprite, Direction.LEFT);
        }
        if (
            tileposx < GameState.activeLayout.length - 1 &&
            tileposy >= 0 &&
            tileposy < GameState.activeLayout[0].length &&
            GameState.activeLayout[tileposx + 1][tileposy].tileType === TileType.WALL
        ) {
            adjust(entity.sprite, GameState.activeLayout[tileposx + 1][tileposy].gameItem.sprite, Direction.RIGHT);
        }
        if (
            tileposy > 0 &&
            tileposx >= 0 &&
            tileposx < GameState.activeLayout.length &&
            GameState.activeLayout[tileposx][tileposy - 1].tileType === TileType.WALL
        ) {
            adjust(entity.sprite, GameState.activeLayout[tileposx][tileposy - 1].gameItem.sprite, Direction.TOP);
        }
        if (
            tileposy < GameState.activeLayout[0].length - 1 &&
            tileposx >= 0 &&
            tileposx < GameState.activeLayout.length &&
            GameState.activeLayout[tileposx][tileposy + 1].tileType === TileType.WALL
        ) {
            adjust(entity.sprite, GameState.activeLayout[tileposx][tileposy + 1].gameItem.sprite, Direction.BOTTOM);
        }
    }

    if (GameState.goalTile && hitGoalTile(GameState.goalTile, entity)) {
        GameState.gameStatus = GameStatus.WON;
    }
}

function hitGoalTile(goalTile: GameTile, entity: GameItem) {
    return isIntersectingSprites(entity.sprite, goalTile.gameItem.sprite);
}

// function simulateMonsters(timeDelta) {
//     const timeFactor = timeDelta / 60;
//     GameState.monsters.forEach((m) => {
//         const lastX = m.sprite.x;
//         const lastY = m.sprite.y;
//         m.sprite.x += Math.random() * 1.0 > 0.5 ? SPEED * timeFactor : -1 * SPEED * timeFactor;
//         m.sprite.y += Math.random() * 1.0 > 0.5 ? SPEED * timeFactor : -1 * SPEED * timeFactor;

//         const [isCollidingWithXAxisWall, isCollidingWithYAxisWall] = isOutsideBounds(m.sprite, {
//             x: 0,
//             y: 0,
//             width: PIXEL_WIDTH,
//             height: PIXEL_HEIGHT,
//         });
//         if (isCollidingWithXAxisWall) {
//             m.sprite.x = lastX;
//         }
//         if (isCollidingWithYAxisWall) {
//             m.sprite.y = lastY;
//         }
//     });
// }
